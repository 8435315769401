import React from 'react';

import { MediumHeaderTypo, BasicParagraphTypo, AllCapsSubTypo, ListTypo } from '../commonStyles/typoTheme';
import { SectionAboutMargin } from '../commonStyles/SectionsStyle';
import { ProcessImage } from '../commonStyles/ImagesStyles';
import { MiddleContainer } from '../commonStyles/Containers';

import { ProcessStageContainer, ProcessIllustration, ProcessText, ProcessList, ProcessListItem, ProcessListItemDot } from './styles/ProcessStyles';

import processPlan from '../images/icons/process_icons/icons2019_plan.svg';
import processDiscover from '../images/icons/process_icons/icons2019_discover.svg';
import processDesign from '../images/icons/process_icons/icons2019_design.svg';
import processCode from '../images/icons/process_icons/icons2019_code.svg';
import processRefine from '../images/icons/process_icons/icons2019_refine.svg';
import processDeliver from '../images/icons/process_icons/icons2019_deliver.svg';

export const ProcessStages = [
  { id: 1,
    icon: processDiscover,
    title:'Discover',
    subtitle: 'stage 1',
    description: 'Not two projects are the same, so we want to immerse ourselves in your business, learn about your team and audience. That way, we can truly understand your expectations and needs.',
    weDeliver:['creative brief', 'goals and tasks', 'user journey', 'interviews & surveys', 'market research'],
    isFloatRight: true,
    isFirst: true
  },
  {
    id: 2,
    icon: processPlan,
    title: 'Plan',
    subtitle: 'stage 2',
    description: 'We don`t want things that just look good. It is also about reflecting your company and and converting visitors into paying customers. That`s why in this stage we focus on ideas, exploration, sketches(lots of sketches) and moodboards. Eventually, these concepts emerge into digital products that will win your customers hearts and minds.',
    weDeliver:['information architecture', 'moodboards', 'wireframes', 'prototypes'],
    isFloatRight: false
  },
  {
    id: 3,
    icon: processDesign,
    title: 'Design',
    subtitle: 'stage 3',
    weDeliver:['hi-fi wireframes', 'brand identity', 'logo', 'illustration', 'website&app design'],
    description: 'In this step, we are focusing on color palettes, shapes, fonts and illustrations. we spend time polishing your brand identity and designing the website.',
    isFloatRight: true
  },
  {
    id: 4,
    icon: processCode,
    title: 'Code',
    subtitle: 'stage 4',
    weDeliver:['front-end', 'back-end', 'animations', 'responsiveness'],
    description: 'Based on solid foundations, we enter the development stage. Our team concentrates on effectivness and builds a stunning, responsive digital experience that works on multiple screen sizes and devices.',
    isFloatRight: false
  },
  {
    id: 5,
    icon: processRefine,
    title: 'Refine',
    subtitle: 'stage 5',
    weDeliver:['testing', 'usability studies'],
    description: 'Implement, measure, optimise. In this stage we will measure every detail, check for simple errors or browser bugs.',
    isFloatRight: true
  },
  {
    id: 6,
    icon: processDeliver,
    title: 'Deliver and launch',
    subtitle: 'stage 6',
    weDeliver:['launch', 'future-proofing', 'design system', 'brand guidelines', 'social media assets'],
    description: 'Once your digital experience is ready, it`s time for a launch! We will help you with that. And when it comes to your brand identity - We know times are changing, and companies are growing. This is why we will also deliver future-proof design system, so your business can evolve and grow.',
    isFloatRight: false
  }
];



function renderStages(stages, i) {
  return (
    <ProcessStageContainer key={'ProcessStageContainer-'+stages.id} isFirst={stages.isFirst}>
      <ProcessIllustration isRight={stages.isFloatRight}>
        <ProcessImage bgImg={'url(' + stages.icon + ')'}/>
      </ProcessIllustration>
        <ProcessText isRight={stages.isFloatRight}>
          <AllCapsSubTypo>
            {stages.subtitle}
          </AllCapsSubTypo>
          <MediumHeaderTypo>
            {stages.title}
          </MediumHeaderTypo>
          <BasicParagraphTypo>
            {stages.description}
            <ProcessList>
              <AllCapsSubTypo>
                Typical deliverables in this stage:
              </AllCapsSubTypo>
              {stages.weDeliver.map((item, b) => {
                return (
                  <ProcessListItem key={'ProcessListItem-'+b+'-'+stages.id}>
                    <ListTypo>
                      <ProcessListItemDot>&#9900;</ProcessListItemDot> {item}
                    </ListTypo>
                  </ProcessListItem>
                );
              })}
            </ProcessList>
          </BasicParagraphTypo>
        </ProcessText>
    </ProcessStageContainer>
  );
}


class SectionAboutUsTeam extends React.PureComponent {

  render() {
      return (
        <>
        <SectionAboutMargin>
          <MiddleContainer>
            {ProcessStages.map((stages, i) => renderStages(stages, i))}
          </MiddleContainer>
        </SectionAboutMargin>
        </>
      );
  }
}

export default SectionAboutUsTeam;
