import React from 'react';

import { MediumHeaderTypo, BasicParagraphTypo } from '../commonStyles/typoTheme';
import { SectionAboutMargin } from '../commonStyles/SectionsStyle';
import { MiddleContainer } from '../commonStyles/Containers';
import { TextContainerColumns, TextColumn } from '../commonStyles/TextStyles';


class SectionAboutUsDescription extends React.PureComponent {

  render() {
      return (
        <SectionAboutMargin isFirst={true}>
          <MiddleContainer>
            <TextContainerColumns>
              <TextColumn hasPaddingRight={true}>
                <MediumHeaderTypo>
                  Our approach
                </MediumHeaderTypo>
                <BasicParagraphTypo>
                  We are passionate about delivering high quality experiences that meet your expectations. That's why our approach to working with clients is a partnership. We want to share our points of view and keep in touch often.
                </BasicParagraphTypo>
              </TextColumn>
            </TextContainerColumns>
          </MiddleContainer>
        </SectionAboutMargin>
      );
  }
}

export default SectionAboutUsDescription;
