import React from 'react';

import { MediumHeaderTypo, BasicParagraphTypo, AllCapsSubTypo } from '../commonStyles/typoTheme';
import { MiddleContainer } from '../commonStyles/Containers';
import { TextContainerCenter } from '../commonStyles/TextStyles';
import { WideImage } from '../commonStyles/ImagesStyles';
import { SectionAboutMargin } from '../commonStyles/SectionsStyle';

import { AboutUsLocationImage, AboutUsLocationButton } from './styles/AboutStyles';
import { CenteredButton } from '../commonStyles/ButtonStyles';

import Button from './Button';
import HorizontalLine from './HorizontalLine';

import processImg from '../images/process.jpg';


class SectionAboutUsLocation extends React.PureComponent {

  render() {
      return (
        <SectionAboutMargin>
          <AboutUsLocationImage>
            <WideImage
              bgImg={'url(' + processImg + ')'}
              blend={'#313131'}
            />
          </AboutUsLocationImage>
          <MiddleContainer>
            <TextContainerCenter>
              <MediumHeaderTypo>
                How we work
              </MediumHeaderTypo>
              <BasicParagraphTypo>
                These five stages help us deliver unique brand identities, websites and digital products. But, since our creative process revolves entirely around you - how we work depends on your specific needs and goals.
              </BasicParagraphTypo>
            <AllCapsSubTypo>
              Want to hear more about our approach? Contact us:
            </AllCapsSubTypo>
          </TextContainerCenter>
          <AboutUsLocationButton>
            <CenteredButton>
              <Button name={'bcc@border3px.com'} href={'mailto:bcc@border3px.com'} />
            </CenteredButton>
          </AboutUsLocationButton>
          <HorizontalLine margin={'0'}/>
          </MiddleContainer>
        </SectionAboutMargin>
      );
  }
}

export default SectionAboutUsLocation;
