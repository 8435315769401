import styled from "styled-components";

export const ProcessWideImage = styled.div`
  margin-bottom: 10rem;

  /* 1024px */
  @media (min-width: 64em) {
    margin-bottom: 20rem;
  }
`;


export const ProcessStageContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 5rem 0;

  /* 1280px */
  @media (min-width: 80em) {
    margin: ${ props => props.isFirst ? '0' : '15rem 0'};
  }
`;

export const ProcessIllustration = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  float: left;

  /* 1280px */
  @media (min-width: 80em) {
    width: 40%;
    float: ${ props => props.isRight ? 'right' : 'left'};
  }
`;

export const ProcessText = styled.div`
display: inline-block;
position: relative;
width: 100%;
float: left;

/* 1280px */
@media (min-width: 80em) {
  width: 60%;
  float: ${ props => props.isRight ? 'left' : 'right'};
  padding: ${ props => props.isRight ? '0 8rem 0 0' : '0 0 0 8rem'};
}
`;

export const ProcessList = styled.div`
  display: block;
  position: relative;
  width: fit-content;
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px dotted;
  border-color: ${ props => props.theme.secondaryColor };
`;

export const ProcessListItem = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin-top: 1.2rem;
`;

export const ProcessListItemDot = styled.div`
  display: inline-block;
  position: relative;
  margin: 0 2rem;
  color: ${ props => props.theme.secondaryColor };
`;
