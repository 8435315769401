import React from 'react';
import {ThemeProvider} from 'styled-components';


import themes from '../commonStyles/themes';
import { MainContainer, ThemeContainer } from '../commonStyles/Containers';

import SEO from '../components/seo';
import AllFixedElements from '../components/AllFixedElements';
import SectionFooter from '../components/SectionFooter';
import SectionProcess from '../components/SectionProcess';

import GlobalStyle from '../components/layoutCss';


class ProcessPage extends React.PureComponent {

  render() {
    return (
      <>
        <GlobalStyle />
        <ThemeProvider theme={themes.themeMain}>
          <ThemeContainer>
            <SEO
              title='About'
              path={this.props.path}
            />
            <AllFixedElements current={'process'}  isCaseStudy={true}  />
            <MainContainer>
              <SectionProcess />
              <SectionFooter />
            </MainContainer>
          </ThemeContainer>
        </ThemeProvider>
      </>
    );
  }
}
export default ProcessPage;
