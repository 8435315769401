import React from 'react';

import SectionProcessDescription from './SectionProcessDescription';
import SectionProcessDescription2 from './SectionProcessDescription2';
import SectionProcessStage from './SectionProcessStage';

class SectionProcess extends React.PureComponent {

  render() {
      return (
        <>
          <SectionProcessDescription />
          <SectionProcessDescription2 />
          <SectionProcessStage />
        </>
      );
  }
}

export default SectionProcess;
